import './style';
import { Component } from 'preact';
import Router from 'preact-router';

import Landing from './pages/Landing';
import Services from './pages/Services';
import About from './pages/About';
import Navbar from './components/style/Navbar';
import NeuaFooter from './components/NeuaFooter';

export default class App extends Component {
	render() {
		return (
			<>
			<Navbar />
			<Router>
				<Landing path='/' title='ClearConnect Data and Security'/>
				<About path='/about' title='ClearConnect | About Us' />
				<Services path='/services' title='ClearConnect | Services' />
			</Router>
			<NeuaFooter />
			</>
		);
	}
}
