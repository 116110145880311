import { h } from 'preact';
import styled from 'styled-components';
import theme from './style/theme'

const Container = styled.div`
    padding-bottom: 40px;
    padding-top: 20px;
    background: ${theme.accent};
    color: ${theme.textAlt};
`
const Title = styled.h2`
    font-size: 30pt;
    font-weight: bold;
`

const Blurb = styled.p`
    margin: 30px auto;
    font-size: 18pt;
    font-weight: 500;
    max-width: 90%;
    width: 700px;
`

const ContactItem = styled.div`
    display: inline-block;
    background: ${theme.background};
    color: ${theme.accent};
    font-size: 14pt;
    font-weight: 600;
    padding: 10px;
    width: 280px;
    margin: 10px;
    transition: all .2s ease-in-out;
    &:hover { transform: scale(1.05); cursor: pointer; }

    @media (max-width: 340px) {
        max-width: 80%;
        font-size: 14pt;
    }
`

export default function ContactFooter() {

    const email = () => window.location.href = 'mailto:admin@clearconnect.com.au'
    const phone = () => window.location.href = 'tel:0733962875'

    return (
        <Container>
            <Title>Contact Us</Title>
            <Blurb>
                Need a quality install or service from one of our experienced technicians at a 
                competitive price? Contact us through one of the channels below and we'll get 
                you started with a free quote.
                <br />
                <br />
                <ContactItem onClick={phone}>07 33 96 28 75</ContactItem> 
                <ContactItem onClick={email}>admin@clearconnect.com.au</ContactItem>
            </Blurb>
        </Container>
    )
}