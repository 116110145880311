import { h } from 'preact';
import styled from 'styled-components'
import theme from './theme'

import logo from '../../assets/logo-camera-only.png'

const Header = styled.header`
    position: sticky;
    left: 0;
    top: 0;
    width: 100%;
    height: 56px;
    padding: 0;
    background: ${theme.accent};
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    z-index: 50;

    @media (max-width: 768px) {
        height: auto;
    }
`

const Logo = styled.img`
    width: 60px;
    margin: -7px 10px 0px 0px;
    float: left;

    @media (max-width: 768px) {
        display: none;
    }

`

const Subtitle = styled.span`
    font-weight: 600;
    padding-left: 2px;
`

const Title = styled.h1`
    float: left;
    margin: 0;
    padding: 0 15px;
    font-size: 24px;
    line-height: 56px;
    font-weight: 800;
    color: ${theme.textAlt};

    @media (max-width: 768px) {
        line-height: 35px;
        font-size: 18px;
        float: none;
    }
`

const Nav = styled.nav`
    margin: 0px 20px;
    float: right;
    font-size: 100%;

    @media (max-width: 768px) {
        float: none;
    }
`

const Link = styled.a`
    display: inline-block;
	height: 56px;
	line-height: 56px;
	padding: 0 15px;
    min-width: 70px;
    font-size: 18px;
	text-align: center;
	background: rgba(255,255,255,0);
	text-decoration: none;
    color: ${theme.textAlt};
    font-weight: bold;
    will-change: background-color;
    &:hover {
        background: #0002;
    }

    @media (max-width: 768px) {
        line-height: 40px;
        height: 40px;
        font-size: 18px;
        float: none;
    }

    @media (max-width: 350px) {
        font-size: 14px;
        padding: 0 10px;
    }
`

export default function Navbar(props) {

    return (
        <Header>
            <Title>
                <Logo src={logo}/>
                ClearConnect
                {' '}
                <Subtitle>Data and Security</Subtitle>
            </Title>
            <Nav>
                <Link href='/' native>Home</Link>
                <Link href='/about' native>About</Link>
                <Link href='/services' native>Services</Link>

            </Nav>
        </Header>
    )
}