import { Component } from 'preact';
import styled from 'styled-components';
import Helmet from 'preact-helmet';
import ContactFooter from '../components/ContactFooter';
import TitleBanner from '../components/style/TitleBanner';

import bannerImg from '../assets/images/service-banner.jpeg'
import cctvImg from '../assets/images/sec-2.jpeg'
import alarmImg from '../assets/images/sec-1.jpeg'
import wifiImg from '../assets/images/wifi-2.jpeg'
import celfiImg from '../assets/images/wifi-1.jpeg'
import cablingImg from '../assets/images/cabling-1.jpeg'
import networkImg from '../assets/images/cabling-1.jpeg'

import Service from '../components/style/ServiceLarge';

const Container = styled.div`
    max-width: 90%;
    width: 1400px;
    margin: auto;
`

export default class Services extends Component {

    componentDidMount  = () => window.scrollTo({ top: 0 });

    render() {
        return (
            <>
                <Helmet 
                    title='ClearConnect | Our Services'
                    meta={[ {name: "description", content: "ClearConnect offers a range of services including \
                    CCTV Systems, Alarm Systems, Wifi and Cel-Fi, and office or residential data cabling."}, ]}
                />
                <TitleBanner title='Services' subtitle='Data. Security. Quality.' image={bannerImg}>
                    We provide all kinds of services ranging from data cabling to home and office security. 
                    We'll help find a solution that best suits your home or business at a competitive price. 
                </TitleBanner>
                <Container>
                    <Service large image={cctvImg} title='CCTV Systems'>
                        <p>We can supply and install pure IP and Hybrid based systems.
                        Pure IP is connected to a Network Video Recorder (NVR) with IP cameras from 2 MP to 12 MP. 
                        These cameras are high definition and require structured cabling.</p>
                        
                        <p>Hybrid base systems can handle Analogue/ HD/ CVI and IP cameras if you wish to upgrade 
                        your camera system while keeping existing cabling and some non key cameras. You can have a mix of 
                        IP cameras, analogue and HDCVI. This is a cost effective way to upgrade your system without 
                        rewiring all of the cameras.</p>

                        <p>Both systems have Smart device apps, so you can monitor from anywhere in the world, 
                        as long as you have an internet connection. We can use the AI (artificial intelligence) 
                        function to set rules to alert the user if there is a detection breach.</p>
                        <p>
                            All of our systems are professionally installed and tested.
                            <br />
                            We service what we sell and have the support and backing of our suppliers.
                        </p>
                    </Service>
                    <Service large image={alarmImg} title='Alarm Systems'>
                        <p>We can supply and install an alarm / Intrusion system with user code access, 
                        and detection devices in the protected areas to alert you and or a monitoring 
                        station of a breach.</p>
                        
                        <p>We partner with a cert1 monitoring station with as little a $1 per day monitoring cost.</p>
                        
                        <p>
                            All of our systems are professionally installed and tested.
                            <br />
                            We service what we sell and have the support and backing of our suppliers.
                        </p>
                    </Service>
                    <Service large image={wifiImg} title='Wifi Installations'>
                        <p>Wireless availability is a growing need for both commercial and residential applications. 
                        WiFi technology allows the creation of access points that provide wireless high-speed internet and 
                        network connections. Whether you need to WiFi-enable a small office or large warehouse, we have the 
                        experience to meet your needs. We can supply and install access points to as many locations as you 
                        may need.</p>
                        
                        <p>We can do a site survey for the best efficiency, and provide a location map.</p>
                        
                        <p>We can provide network cabling to relocate your existing Wireless Access Points in to more 
                        centralised locations to provide better coverage.</p>
                    </Service>
                    <Service large image={celfiImg} title='Cel-Fi and Point to Point'>
                        <p>Cel-Fi boosters are a way of extending the nextG signal at a geographical location or area 
                        such as a basement or Tin shed. Generally from no bars or 1 bar on your mobile device to 4 
                        bars once the Cel-Fi booster is installed.</p>
                        
                        <p>This will increase the signal for your Mobile data transfer as well as the Voice signal to 
                        avoid dropouts and broken conversation.</p>

                        <p>A point to point wireless bridge is a means of connecting networks that are too far or expensive 
                        to run data/ optic fibre; such as remote sheds, dongas or remote sites up to 3 or 4 kms away. 
                        For these locations you would generally need line of sight for point to point bridges to work effectively.</p>
                    </Service>
                    <Service large image={cablingImg} title='Office and Residential Data Cabling'>
                    <p>Your structured cabling infrastructure must maintain high standards for assuring the integrity 
                    and functionality of its computer environment because it is the backbone of every business. 
                    Getting the cabling infrastructure designed and installed properly the first time should be the 
                    top priority. As cabling specialists, we deliver quality network cabling solutions.</p>
                    We Provide the following solutions:
                    <ul>
                    <li>Category 6, 6a, and 8 cable.</li>
                    <li>Termination and testing.</li>
                    <li>Moves and changes.</li>
                    <li>Data Rack supply and  installation.</li>
                    <li>Data rack upgrades out of hours ( nil working hours down time).</li>
                    <li>NBN cable extensions/ relocations.</li>
                    </ul>

                    </Service>
                    <Service large image={networkImg} title='Network Equipment'>
                        <p>We can supply network equipment from:</p>
                        <ul>
                            <li>Patch panels</li>
                            <li>Data Racks</li>
                            <li>Data mechs</li>
                            <li>Patch leads</li>
                            <li>Network switches including POE.</li>
                            <li>POE adaptors</li>
                            <li>Wall plates</li>
                            <li>NVRs and CCTV Cameras</li>
                        </ul>

                    </Service>
                </Container>
                <ContactFooter />
            </>
        )
    }
}