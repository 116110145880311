import { Component } from 'preact';
import Helmet from 'preact-helmet';
import styled from 'styled-components';
import theme from '../components/style/theme';

import ContactFooter from '../components/ContactFooter';
import AboutUs from '../components/AboutUs';
import LandingBanner from '../components/style/LandingBanner';
import Section from '../components/style/Section';
import Button from '../components/style/Button';

// images
import logoImg from '../assets/logobig-light.png';
import cablingImg from '../assets/images/cabling-1.jpeg';
import lockImg from '../assets/images/sec-2.jpeg';
import wifiImg from '../assets/images/wifi-2.jpeg';
import landingImg from '../assets/images/landing-banner.jpeg';

const Logo = styled.img`
    width: 300px;
    margin: 20px 0px;
    display: inline-block;
    @media (max-width: 600px) {
        width: 200px;
        margin: 10px 0px;
    }
`

const Title = styled.h1`
    font-size: 30pt;
    font-weight: bold;
    text-align: left;
    color: ${theme.accent};
    max-width: 570px;

    @media (max-width: 500px) {
        font-size: 24pt;
    }

    @media (max-width: 360px) {
        font-size: 18pt;
    }
`

const Subtitle = styled.h2`
    font-size: 18pt;
    font-weight: bold;
    text-align: left;
    margin: 50px 0px;
    max-width: 500px;

    @media (max-width: 500px) {
        font-size: 14pt;
    }

    @media (max-width: 360px) {
        font-size: 13pt;
        margin: 30px 0px;
    }
`

const Cta = styled.p`
    font-size: 12pt;
    & #action {
        font-weight: 600;
        color: ${theme.accentHighlight};
    }
    @media (max-width: 360px) {
        font-size: 10pt;
        max-width: 80%;
    }
`

export default class Landing extends Component {

    componentDidMount  = () => window.scrollTo({ top: 0 });

    render() {
        return (
            <div>
                <Helmet 
                    title='ClearConnect - CCTV, Alarm Systems Installation, Data Cabling and more'
                    meta={[ {name: "description", content: "ClearConnect is a local business specializing in data and security. \
                    We offer a range of services including; CCTV installations, alarm system installation, network cabling, \
                    wifi installation, Network Equipment. We service both residential and commercial. We operate in most \
                    Eastern bayside suburbs including; Morningside, Cannon Hill, Hemmant, Manly, Wynnum, Capalaba, \
                    Tingalpa, Carindale, Thornside, Birkdale, Alexandra Hills, Chandler, Victoria Point and surrounding \
                    areas."}, ]}
                />
                <LandingBanner image={landingImg}>
                    <Logo src={logoImg} alt='ClearConnect Logo'/>
                    <Title>We give your network and security a clear vision into the future.</Title>
                    <Subtitle>Connecting your home or business with 30+ years of data, CCTV, and security installations and servicing.</Subtitle>
                    <Cta><span id='action'>Continue scrolling</span> to see how we can help your bayside home or business.</Cta>
                </LandingBanner>
                <AboutUs />
                <div>
                <Section 
                    title={`Securing your assets, enhancing your safety systems and protecting those around you \ 
                    at competitive prices you'll love. `}
                    body={`We are proud to offer the latest products, innovations and services, along with quality \ 
                    installation and maintenance services to the Brisbane eastern bayside and surrounds`}
                    image={lockImg}
                    imageDesc='home security system'
                >
                    <Button href='/services'>Learn more about this service</Button>
                </Section>
                <Section
                    left 
                    title='Structured Cabling is built with long-term reliability?' 
                    body={`Our structured cabling systems are future forward and will last you into the next decade, \
                    we will put you on the right track with tomorrow in mind. Lets get it done right the first time.`} 
                    image={cablingImg}
                    imageDesc='cables going into patch panels'
                >
                    <Button href='/services'>Learn more about this service</Button>
                </Section>
                <Section 
                    title={`Need more coverage? We've got you covered.`}
                    body={`Whether it's Wi-Fi, NextG or point to point wireless we can provide a coverage solution to suit your needs. \
                    Do you need better signal coverage?`} 
                    image={wifiImg}
                    imageDesc='phone in hand using wifi'
                >
                    <Button href='/services'>Learn more about this service</Button>
                </Section>
                </div>
                <ContactFooter />
            </div>
        )
    }
}