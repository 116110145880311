import { h } from 'preact';
import styled from 'styled-components'
import theme from './theme'

const S = styled.div`
    position: relative;
    display: flex;
    flex-direction: row;
    ${props => props.left && `flex-direction: row-reverse;`}
    overflow: hidden;
    max-width: 100vw;
    background-color: ${theme.background};
    text-align: left;
    color: ${theme.text};

    @media (max-width: 900px) {
        border-bottom: 20px solid ${theme.accent};
        &:last-child { border-bottom: none; }
    }
`

const Container = styled.div`
    margin: 80px 12%;
    max-width: 400px;
    width: 30%;
    
    @media (max-width: 900px) {
        width: 100%;
        padding: 60px 7%;
        margin: 0px 0%;
        ${props => props.image && `
            background: linear-gradient(0deg, #000b, #000b),url(${props.image}); 
            background-position: center; 
            background-size: cover;
        `}
    }
`

const Title = styled.h2`
    font-size: 22pt;
    margin: 50px 0px;
    color: ${theme.accentHighlight};
    @media (max-width: 900px) {
        margin: 20px 0px;
    }
`

const Body = styled.p`
    font-size: 16pt;
    line-height: 33px;
    margin: 50px 0px;
`

const ImageContainer = styled.div`
    position: absolute;
    overflow: hidden;
    top: 0%;
    left: 50%;
    border-radius: 500px 0px 0px 500px;
    width: 50%;

    @media (max-width: 900px) {
        display: none;
    }

    ${props => props.left && `
        left: auto;
        right: 50%;
        transform: scale(-1, 1);
    `}
`

const Image = styled.img`    
    width: 100%;
`

export default function Section({ title, body, left, children, image, imageDesc }) {

    return (
        <S left={left}>
            <Container image={image}>
                <Title>{title}</Title>
                <Body>{body}</Body>
                {children}
            </Container>
            <ImageContainer left={left}>
                <Image src={image} alt={imageDesc} />
            </ImageContainer>
        </S>
    )
}