
import { h } from 'preact';
import styled from 'styled-components'
import theme from './theme'

const Container = styled.div`
    width: 500px;
    min-height: 600px;
    margin: 20px 20px;
    padding: 20px;
    display: inline-flex;
    align-items: top;
    flex-direction: column;
    text-align: left;
    background: white;
    color: #222;
    font-size: 11pt;
    font-weight: 500;
    -webkit-box-shadow: 0px 10px 50px -25px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 10px 50px -25px rgba(0,0,0,0.2);
    box-shadow: 0px 10px 50px -25px rgba(0,0,0,0.2);

    max-width: 80%;
`

const Titlebox = styled.div`
    position: relative;
    height: 60px;
    width: 100%;
    text-align: center;
`

const Titleline = styled.div`
    background: ${theme.accent};
    height: 3px;
    width: 80%;
    position: absolute;
    top: 0px;
    left: 10%;
    transform: translate(0px, 32px);

    @media (max-width: 768px) {
        width: 90%;
        left: 5%;
    }
`

const Title = styled.h1`
    line-height: 60px;
    position: relative;
    display: inline-block;
    z-index: 10;
    margin: 0px;
    top: 0px;
    padding: 0px 10px;
    background: #fff;
    font-size: 16pt;

    @media (max-width: 768px) {
        font-size: 12pt;
    }
`

const ImageContainer = styled.div`
    margin: 0px auto;
    border-radius: 50%;
    width: 200px;
    height: 200px;
    overflow: hidden;
    border: 8px solid #222;
`

const Image = styled.img`
    height: 100%;
`

export default function ServiceLarge({ title, children, image }) {

    return (
        <Container>
            <ImageContainer>
                <Image src={image} />
            </ImageContainer>
            <Titlebox>
                <Titleline />
                <Title>{title}</Title>
            </Titlebox>
            {children}
        </Container>
    )
}