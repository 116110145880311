import { h } from 'preact';
import styled from 'styled-components'
import theme from './theme'

const B = styled.a`
    border-radius: 20px;
    padding: 10px 20px;
    background: ${theme.accent};
    color: ${theme.textAlt};
    font-size: 14pt;
    font-weight: bold;
    border: none;
    text-decoration: none;
    transition: all .2s ease-in-out;
    &:hover { 
        transform: scale(1.05); 
        cursor: pointer; 
        background: ${theme.accentHighlight}; 
    }
    &:focus {
        outline: none;
    }
`

export default function Button(props) {

    return <B {...props} />
}