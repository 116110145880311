import { h } from 'preact';
import styled from 'styled-components';
import theme from './theme'

const Container = styled.div`
    padding: 40px 0px;
    width: 100%;
    color: ${theme.text};
    background:linear-gradient(90deg, #010000ea, #010000ae), url(${props => props.image});
    background-position: right center;
    background-repeat: no-repeat;
    background-size: cover;
    border-bottom: 10px solid ${theme.accent};
`

const Left = styled.div`
    display: inline-block;
    text-align: left;
    padding: 0px 20px;
`
const Right = styled.div`
    display: inline-block;
    text-align: left;
    padding: 0px 20px;
    font-size: 14pt;
    font-weight: 500;
    line-height: 30px;
    max-width: 400px;
    margin: 20px;
`

const Title = styled.h1`
color: ${theme.accent};
font-size: 30pt;
text-align: left;
margin: 0px;
`

const Subtitle = styled.h2`
text-align: left;
margin: 0px;
`

export default function TitleBanner({ title, subtitle, children, image }) {

    return (
        <Container image={image}>
            <Left>
                <Title>{title}</Title>
                <Subtitle>{subtitle}</Subtitle>
            </Left>
            <Right>
                {children}
            </Right>     
        </Container>
    )
}