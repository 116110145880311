import { h } from 'preact';
import styled from 'styled-components'
import theme from './theme'

const Container = styled.div`
    display: inline-block;
    background: ${theme.backgroundAlt};
    color: ${theme.textAlt};
    margin: 10px 20px;
    max-width: 300px;
    width: 90%;
    height: 630px;
    font-weight: 500;
    -webkit-box-shadow: 0px 10px 46px -19px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 10px 46px -19px rgba(0,0,0,0.4);
    box-shadow: 0px 10px 46px -19px rgba(0,0,0,0.4);

    overflow-x: hidden;
    transition: all .2s ease-in-out;
    &:hover { 
        transform: scale(1.01) translate(0, -5px); 
        cursor: pointer; 
    }

    @media (max-width: 600px) {
        margin: 10px 0px;
    }
`

const Image = styled.img`
    height: 250px;
    margin-bottom: -4px;
`



const Title = styled.p`
    font-weight: bold;
    font-size: 16pt;
    padding: 10px 20px;
    background: ${theme.accent};
    color: ${theme.textAlt};
    margin: 0px;
    margin-bottom: 20px;
`

const Description = styled.p`
    font-size: 11pt;
    padding: 0px 25px;
    margin: 0px;
    line-height: 20px;
    text-align: left;
`

export default function Service(props) {
    const { large, title, children, image, imageDesc } = props

    return (
        <Container>
            <Image src={image} alt={imageDesc}/>
            <Title>{title}</Title>
            <Description>{children}</Description>
        </Container>
    )
}