import { h } from 'preact';
import styled from 'styled-components';
import theme from './style/theme';
import logoImg from '../assets/images/neualogo.svg'

const Container = styled.div`
    display: flex;
    align-items: center;

    height: 40px;
    width: 100%;
    background: ${theme.background};
    color: #666;
    line-height: 40px;
    text-align: left;

    @media (max-width: 500) {
        font-size: 8pt;
    }
`

const Logo = styled.img`
    display: inline-block;
    height: 2ch;
    margin: 0px 8px;
`
const Link = styled.a`
    color: #777;
    font-weight: bold;
    margin: 0px 5px;
    text-decoration: none;

    transition: all .1s ease-in-out;

    &:hover {
        color: #999;
    }
`

export default function NeuaFooter() {

    return (
        <Container>
            <Logo src={logoImg} alt='neua software logo' />
                website designed and constructed by
                <Link href='https://www.neua.com.au'>Neua Software</Link>
                2020.
        </Container>
    )
}