import { Component } from 'preact';
import Helmet from 'preact-helmet';
import styled from 'styled-components';
import theme from '../components/style/theme';

import ContactFooter from '../components/ContactFooter';
import TitleBanner from '../components/style/TitleBanner';

import bannerImg from '../assets/images/service-banner.jpeg'
import logo from '../assets/logobig.png';

const Container = styled.div`
    margin: 40px auto;
    width: 1000px;
    display: inline-block;
    max-width: 90%;
    font-size: 18pt !important;
    background: ${theme.backgroundAlt};
    color: ${theme.textAlt};
`

const Text = styled.div`
    float: left;
    width: 550px;
    @media (max-width: 600px) {
        float: none;
        max-width: 100%;
    }
`

const Logo = styled.img`
    width: 400px;
    float: right;
    margin: 20px;
    @media (max-width: 600px) {
        width: 300px;
        float: none;
    }
`

export default class About extends Component {

    componentDidMount  = () => window.scrollTo({ top: 0 });

    render() {
        return (
            <div>
                <Helmet 
                    title='ClearConnect | About Us'
                    meta={[ {name: "description", content: "ClearConnect Data and Security has 30+ years of providing \
                     installation of data cabling, CCTV and alarm systems. We operate primarily in the Brisbane eastern \
                     bayside area."}, ]}
                />
                <TitleBanner title='About' subtitle='Knowledge. Experience. Reliability.' image={bannerImg}>
                    30+ Years of servicing queensland with data, CCTV and security excellence. Locally owned and operated. 
                    Competitive prices and personalized service.
                </TitleBanner>
                <Container>
                <Logo src={logo} ALIGN='left'/>
                <Text>
                <p>
                    Max has been in the industry since 1985 and has 35 years experience in the Telecommunications industry, 
                    he has been installing CCTV and security systems since 2004.
                </p>
                
                <p>
                    ClearConnect Data and Security was established on a strong commitment to providing quality solutions that 
                    exceed our customers expectations every time.
                </p>
                
                <p>
                    Our goal is to share information on the latest advancements in security and data convergence with our 
                    customers and to develop their systems as the need requires.
                </p>
                
                <p>
                    As an independent business communications specialist, we support companies in all areas of security 
                    and data solutions. We give advice on developing communications and security infrastructure.
                </p>

                <p>
                    We consult on the best solution for your needs tailored to your budget. Our motto is; it needs to be 
                    done right the first time, if we wouldn't do it in our house we certainly won’t do it in yours.
                </p>
                </Text>
                </Container>
                <ContactFooter />
            </div>
        )
    }
}