import { h } from 'preact';
import styled from 'styled-components'
import theme from './style/theme'

import Service from './style/Service';

import dataImg from '../assets/images/cabling-1.jpeg';
import lockImg from '../assets/images/sec-1.jpeg';
import wifiImg from '../assets/images/wifi-1.jpeg';

const Container = styled.div`
    padding-bottom: 80px;
    padding-top: 20px;
    background: ${theme.backgroundAlt};
    color: ${theme.textAlt};
    border-bottom: 20px solid ${theme.accent};
    border-top: 20px solid ${theme.accent};
`

const Title = styled.h2`
    font-size: 30pt;
    margin: 20px;
`

const Blurb = styled.p`
    margin: 30px auto;
    font-size: 16pt;
    font-weight: 500;
    max-width: 90%;
    width: 600px;
`

export default function AboutUs() {

    return (
        <Container>
            <Blurb>
                <b>ClearConnect</b> is a local business offering data and security services. We specialize in residential
                and commercial CCTV systems, alarm systems, wifi installations, and data cabling solutions.
                {/* is a local data and security business offering residential and commercial data and security  */}
                {/* services operating in the Brisbane east and bayside area.  */}
                <br />
                <br />
                Operating in the Brisbane east and bayside area, our goal is to keep your home or office secure and connected.
            </Blurb>
            <Title>What We Do</Title>
            <Service image={lockImg} imageDesc='security system' title='Office and Residential CCTV and Security'>
                We  can install CCTV and Alarm systems in your office or at home, these can be self monitored with the aid of smart 
                apps, or back to base monitoring. We provide quality installations that provide the latest in technology to protect 
                your assets 24/7, providing you with peace of mind for years to come. Smart apps allow you to see what’s happening 
                at your premises in an instant. Ask us about the latest in AI Smart Cameras.
            </Service>
            <Service image={wifiImg}  imageDesc='wifi router' title='Wifi, Wireless Bridge and Cel-Fi Installations'>
                Are you having trouble with sketchy WiFi signal? Let us install your wireless access point (WAP) in the right place, 
                or design a WAP system to suit your needs. Do you need to connect another site, a rear shed or a warehouse close by? 
                Talk to us about wireless bridge, a one off cost could save you thousands in the future. Have you got an area where 
                your Mobile phone has low CEL signal and keeps dropping out, ask us about Cel-Fi repeaters.
            </Service>
            <Service image={dataImg} imageDesc='cabling into patch panels' title='Office and Residential Data Cabling'>
                With structured cabling being more important for the reliability of network efficiency with the convergence 
                of high data speeds and POE devices, the cabling system needs to be installed right the first time. With our 
                background in certified cabling systems we can assure your network cabling system will be future forward 
                and completed to the highest standards. We will only install Cat6, cat6a and cat8 data cabling to ensure your 
                cabling meets the future standards.
            </Service>
        </Container>
    )
}