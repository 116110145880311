import { h } from 'preact';
import styled from 'styled-components';
import theme from './theme'
import covidImg from '../../assets/images/covid-safe-logo.png'

const Container = styled.div`
    position: relative;
    text-align: left;
    width: 100%;
    height: 75vh;
    background-color: #0b0080;
    color: ${theme.text};

    @media (max-width: 600px) {
        height: 95vh;
    }
`
const Banner = styled.div`
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background:linear-gradient(90deg, #010000ea, #01000040), url(${props => props.image});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: cover;
`

const Content = styled.div`
    position: absolute;
    top: 50%;
    left: 10%;
    width: 80%;
    @media (max-width: 600px) {
        width: 90%;
        left: 5%;
    }
    transform: translate(0px, -50%);
`

const CovidLogo = styled.img`
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 100px;

    @media (max-width: 600px) {
        width: 50px;
        right: 10px;
        bottom: 10px;
    }
`

export default function LandingBanner({ children, image }) {

    return (
        <Container>
            <Banner image={image} alt='landing-banner' />
            <Content>{children}</Content>
            <CovidLogo src={covidImg} alt='covid-safe seal'/>
        </Container>
    )
}